import moment from "moment";

export default {
  en: {
    translation: {
      key_gmail: "GMAIL",
      key_facebook_button: "FACEBOOK",
      key_email: "EMAIL",
      key_continue_with: "Continue with",
      key_login_email_placeholder: "Email or Username",
      key_register_email: "Email",
      key_password: "Password",
      key_remember_me: "Remember me",
      key_forgot_password: "Forgot Password",
      key_title_email_confirmation: "Email Confirmation",
      key_description_email_confirmation:
        "Check your inbox and enter the verification code we sent you",
      key_label_resend: "Didn't receive the code?",
      key_action_resend: "RESEND",
      key_placeholder_verification_code: "Verification Code",
      key_button_login: "LOGIN",
      key_button_join: "JOIN",
      key_button_start_chat: "START A CHAT",
      key_error_invalid_credential: "Incorrect email, username or password",
      key_popular: "Popular",
      key_trending: "Trending",
      key_recommended: "Recommended",
      key_date: "Date",
      key_view_all: "VIEW ALL",
      key_explore: "Explore",
      key_explore_title: "EXPLORE",
      key_days: "DAYS",
      key_hrs: "HRS",
      key_min: "MIN",
      key_sec: "SEC",
      key_virtual: "Virtual",
      key_in_person: "In-person",
      key_save: "SAVE",
      key_btn_save: "Save",
      key_btn_follow: "Follow",
      key_location: "Location",
      key_spotify: "Spotify",
      key_venues: "Venues",
      key_events: "Events",
      key_music: "Music",
      key_apply: "Apply",
      key_search: "Search",
      key_button_search: "SEARCH",
      key_button_show: "SHOW",
      key_button_clear_all: "CLEAR ALL",
      key_button_clear: "CLEAR",
      key_button_apply: "APPLY",
      key_follow: "Follow",
      key_cancel: "Cancel",
      key_btn_going: "GOING",
      key_share: "SHARE",
      key_saved: "Saved",
      key_going: "Going",
      key_connections: "Connections",
      key_close: "CLOSE",
      key_add_to_calendar: "Add to Calendar",
      key_add_to_edition: "Editions",
      key_booking: "Booking",
      key_add_ticket_link: "Add Ticket Link",
      key_id_profile: "ID Profile",
      key_contact_host: "Contact",
      key_update: "Updates",
      key_report: "Report",
      key_readmore: "READ MORE",
      key_readless: "READ LESS",
      key_join: "JOIN",
      key_popularity: "Popularity",
      key_lineup: "Lineup",
      key_media: "Media",
      key_photo: "Photos",
      key_video: "Videos",
      key_wall: "Wall",
      key_official: "Offical",
      key_last: "Last",
      key_host: "Host",
      key_promoter: "Promoter",
      key_filter: "Filter",
      key_title_filter: "FILTER",
      key_list: "List",
      key_next_in: "Next in",
      key_media_all: "All",
      key_media_mix: "Mix",
      key_media_albums: "Albums",
      key_photos: "photos",
      key_calendar: "Calendar",
      key_sort: "Sort",
      key_country: "Country",
      key_view: "View",
      key_lineup_artists: "Artists",
      key_lineup_headliners: "Headliners",
      key_lineup_stages: "Stages",
      key_genre_electronic: "ELECTRONIC",
      key_genre_hip_hop: "HIP HOP",
      key_genre_pop: "POP",
      key_genre_r_b: "R&B",
      key_sort_option_popularity: "Popularity",
      key_sort_option_genre: "Genre",
      key_sort_option_recommended: "Recommended",
      key_sort_option_trending: "Trending",
      key_sort_option_price: "Price",
      key_sort_option_rating: "Rating",
      key_sort_option_connections: "Connections",
      key_sort_option_low_high: "Low-High",
      key_sort_option_high_low: "High-Low",
      key_sort_option_name: "Name",
      key_sort_option_a_z: "A-Z",
      key_sort_option_z_a: "Z-A",
      key_sort_option_recent: "Recent",
      key_sort_option_date: "Date",
      key_sort_option_rating_high: "Rating (High)",
      key_sort_option_rating_low: "Rating (Low)",
      key_sort_option_stage: "Stage",
      key_all: "All",
      key_view_type_list: "List",
      key_view_type_grid: "Grid",
      key_view_type_poster: "Poster",
      key_view_type_updates: "Updates",
      key_profile_status_followers: "Followers",
      key_profile_status_followings: "Following",
      key_profile_status_connections: "Connections",
      key_profile_status_events: "Events",
      key_artist_profile_in_tour: "On Tour",
      key_button_follow: "FOLLOW",
      key_button_contact: "CONTACT",
      key_button_share: "SHARE",
      key_button_calendar: "Calendar",
      key_button_connect: "CONNECT",
      key_button_disconnect: "DISCONNECT",
      key_insight_amenities: "Amenities",
      key_insight_info: "Info",
      key_title_amenities: "Amenities",
      key_wall_official: "Official",
      key_wall_community: "Community",
      key_wall_forum: "Forum",
      key_events: "Events",
      key_memories: "Memories",
      key_about_next: "Next in",
      key_action_unfollow: "Unfollow",
      key_action_follow: "Follow",
      key_action_block: "Block",
      key_action_report: "Report",
      key_event_events: "Events",
      key_event_artist: "Artists",
      key_event_country: "Country",
      key_event_countries: "Countries",
      key_event_date_upcoming: "Upcoming",
      key_event_date_past: "Past",
      key_event_view_option_events: "Events",
      key_event_view_option_countries: "Countries",
      key_event_view_option_artists: "Artists",
      key_profile_button_copy: "COPY",
      key_profile_button_cancel: "CANCEL",
      key_from_event: "From event",
      key_report_other: "Other",
      key_report_success_title: "Thanks for letting us know!",
      key_nav_title_followers: "Followers",
      key_female: "Female",
      key_male: "Male",
      key_other: "Other",
      key_private_mode: "Private Mode",
      key_title_update: "UPDATES",
      key_modal_title_contact: "Contact",
      key_modal_title_booking: "Book",
      key_modal_title_calendar: "Calendar",
      key_modal_title_share: "Share",
      key_button_chat: "CHAT",
      key_private_mode_text: "This account is private",
      key_button_accomodation: "ACCOMMODATION",
      key_button_transport: "Transport",
      key_button_google_map: "Map",
      key_button_send: "SEND",
      key_paste_link_below: "Paste link below",
      key_add_ticket_link_name_placeholder: "Ticket seller name",
      key_add_ticket_link_minimum_price_placeholder: "Ticket minimum price",
      key_add_ticket_link_url_placeholder: "Ticket URL link",
      key_title_memory: "MEMORY",
      key_vibe: "Vibe",
      key_button_next: "NEXT",
      key_button_finish: "FINISH",
      key_button_prev: "BACK",
      key_complete_action_using: "Complete Action Using",
      key_outlook_calendar: "Outlook",
      key_google_calendar: "Calendar",
      key_button_publish: "PUBLISH",
      key_memory_form_title_placeholder: "Title (Optional)",
      key_memory_form_description_placeholder:
        "Share the details of your experience",
      key_post_form_description_placeholder: "What’s happening?",
      key_remaining: "Remaining",
      key_title_upload_media: "Upload Media",
      key_memory_alert_need_to_upload_media:
        "Sorry...You need to upload media to publish this memory.",
      key_post_alert_need_to_upload_media:
        "Sorry...You need to upload media to publish this post.",
      key_button_clear_all: "CLEAR ALL",
      key_artists: "Artists",
      key_option_all: "All",
      key_title_ticket: "TICKETS",
      key_official: "Official",
      key_ticket_soundclub_rule: `${process.env.NEXT_PUBLIC_APP_NAME} does not sell event tickets directly, it only redirects/links to other ticket sites. The prices and information shown for this event are estimates, the provider will show the exact price and information.`,
      key_on_sale_for: "On sale for ",
      key_left_before_event: "left before the event",
      key_title_post: "POST",
      key_nav_title_feed: "FEED",
      key_menu_account: "Account",
      key_menu_general: "General",
      key_menu_preferences: "Preferences",
      key_menu_settings_privacy: "Settings & Privacy",
      key_menu_notifications: "Notifications",
      key_menu_privacy: "Privacy",
      key_menu_language: "Language",
      key_menu_distance_units: "Distance Units",
      key_menu_change_password: "Change Password",
      key_menu_delete_account: "Delete Account",
      key_menu_help_support: "Help & Support",
      key_menu_help_center: "Help Center",
      key_menu_contribute: "Contribute",
      key_menu_report_problem: "Report a Problem",
      key_menu_about: "About",
      key_menu_rate_app: "Rate App",
      key_menu_friends_share: "Add friends & Share",
      key_menu_add_friends: "Add Friends",
      key_menu_share_app: "Share App",
      key_menu_terms_policies: "Terms & Policies",
      key_menu_community_guidelines: "Community Guidelines",
      key_menu_disclaimer: "Disclaimer",
      key_menu_eula: "EULA",
      key_menu_terms_of_use: "Terms and Conditions",
      key_menu_privacy_policy: "Privacy Policy",
      key_menu_copyright_policy: "Copyright Policy",
      key_menu_logout: "Logout",
      key_title_profile: "PROFILE",
      key_title_contact: "CONTACT",
      key_title_preference: "PREFERENCE",
      key_made_in_london: "MADE IN LONDON",
      key_sound_club: process.env.NEXT_PUBLIC_APP_NAME,
      key_button_add: "ADD",
      key_posts: "Posts",
      key_nav_title_network: "NETWORK",
      key_followers: "Followers",
      key_following: "Following",
      key_button_save: "SAVE",
      key_title_account_general: "GENERAL",
      key_item_account_general_profile: "Profile",
      key_item_account_general_contact: "Contact",
      key_item_account_general_contact_subtitle: "Events, Profiles",
      key_item_account_general_social_media: "Social Media",
      key_item_account_general_social_media_subtitle: "Events, Profiles",
      key_title_edit_profile: "EDIT PROFILE",
      key_title_social_media: "SOCIAL MEDIA",
      key_title_birth_date: "Birth Date",
      key_edit_profile_bio_placeholder: "Bio",
      key_edit_profile_nickname_placeholder: "Nickname",
      key_edit_profile_firstname_placeholder: "First Name (ID)",
      key_edit_profile_lastname_placeholder: "Last Name (ID)",
      key_edit_profile_username_placeholder: "Username",
      key_edit_profile_birthdate_placeholder: "Birth Date",
      key_edit_profile_gender_placeholder: "Gender",
      key_edit_profile_location_placeholder: "Location",
      key_edit_profile_public_contact: "Public Contact",
      key_edit_profile_email_placeholder: "Email",
      key_edit_profile_phone_number_placeholder: "Phone Number",
      key_edit_profile_website_placeholder: "Website",
      key_edit_profile_social_media: "Public Social Media",
      key_title: "Add",
      key_button_plus_add: "+ ADD",
      key_add_social_media_link_description:
        "Choose social media you want to display on your profile from the list below",
      key_name_link_placeholder: "Name link",
      key_your_link_placeholder: "Your link",
      key_your_username_placeholder: "your username",
      key_button_cancel: "CANCEL",
      key_button_add: "ADD",
      key_button_ticket: "Tickets",
      key_button_booking: "BOOK",
      key_button_confirm: "CONFIRM",
      key_visit_date: "Visit Date",
      key_title_advanced_settings: "ADVANCED SETTINGS",
      key_also_post_on: "Also post on",
      key_twitter: "Twitter",
      key_facebook: "Facebook",
      key_instagram: "Instagram",
      key_google: "Google",
      key_apple: "Apple",
      key_every_one_can_comment: "Everyone can comment",
      key_who_can_reply: "Who can reply",
      key_map: "Map",
      key_accomodation: "Accommodation",
      key_transport: "Transport",
      key_error_msg_enter_email: "Please input email or username",
      key_error_msg_enter_password: "Please input password",
      key_error_msg_invalid_email: "Invalid Email",
      key_error_msg_password_required: "Password required",
      key_error_msg_password_only_alphanum:
        "Password only support alphanumeric characters",
      key_error_msg_password_upper_lower:
        "Password must have uppercase and lowercase alphabetic characters",
      key_error_msg_password_min_eight: "Password minimum 8 characters",
      key_error_msg_old_password_new_password_not_same:
        "The new password cannot be the same as the old password",

      key_privacy_policy_head: `By clicking Join, you accept and agree to ${process.env.NEXT_PUBLIC_APP_NAME}’s `,
      key_terms_and_conditions: "Terms & Conditions",
      key_privacy_policy: "Privacy Policy",
      key_and: "and",
      key_title_account_notifications: "NOTIFICATIONS",
      key_item_account_notifications_social: "Social",
      key_item_account_notifications_updates: "Updates",
      key_item_account_notifications_recommendations: "Recommendations",
      key_item_account_notifications_social_subtitle:
        "Chat, Requests, Interactions",
      key_item_account_notifications_updates_subtitle:
        "Followed events, followed profiles",
      key_item_account_notifications_recommendations_subtitle:
        "Events, Profiles",
      key_title_account_social: "SOCIAL",
      key_title_account_updates: "UPDATES",
      key_title_account_recommendations: "RECOMMENDATIONS",
      key_title_account_language: "LANGUAGE",
      key_title_account_distance_unit: "DISTANCE UNITS",
      key_title_account_change_password: "CHANGE PASSWORD",
      key_title_account_forgot_password: "FORGOT PASSWORD",
      key_title_account_verification_code: "VERIFICATION CODE",
      key_title_account_create_new_password: "CREATE NEW PASSWORD",
      key_title_account_delete_account: "DELETE ACCOUNT",
      key_title_account_feedback: "FEEDBACK",
      key_title_account_report_problem: "REPORT A PROBLEM",
      key_title_account_share_app: "SHARE APP",
      key_title_account_add_friends: "ADD FRIENDS",
      key_title_account_about: "ABOUT",
      key_title_account_disclaimer: "DISCLAIMER",
      key_title_account_eula: "EULA",
      key_title_account_privacy: "PRIVACY",
      key_title_account_privacy_private: "Private",
      key_title_account_privacy_public: "Public",
      key_title_account_help_center: "HELP CENTER",
      key_title_account_contribute: "CONTRIBUTE",
      key_title_account_community: "COMMUNITY",
      key_title_account_term_of_use: "TERMS AND CONDITIONS",
      key_title_account_privacy_policy: "PRIVACY POLICY",
      key_title_account_copyright_policy: "COPYRIGHT POLICY",
      key_title_notification: "NOTIFICATIONS",
      key_subtitle_account_social_chat: "Chat",
      key_subtitle_account_social_request: "Request",
      key_subtitle_account_social_interactions: "Interactions",
      key_subtitle_account_updates_followed_events: "Followed events",
      key_subtitle_account_updates_followed_profiles: "Followed profiles",
      key_subtitle_account_recommendations_events: "Events",
      key_subtitle_account_recommendations_profiles: "Profiles",
      key_subtitle_account_language_english: "English EN",
      key_subtitle_account_distance_miles: "Miles",
      key_subtitle_account_distance_kilometers: "Kilometers",
      key_enter_old_password_placeholder: "Enter old password",
      key_enter_new_password_placeholder: "Enter new password",
      key_enter_email_address: "Email address",
      key_confirm_new_password_placeholder: "Confirm new password",
      key_enter_verification_code: "Verification Code",
      key_enter_search: "Search",
      key_enter_new_password: "Enter new password",
      key_enter_confirm_new_password: "Confirm new password",
      key_enter_opinion: "Please share with us your opinion",
      key_enter_your_password: "Enter Your Password",
      key_enter_confirm_password: "Confirm Password",
      key_tell_us_more_about_you: "Tell Us More About You",
      key_city: "City",
      key_your_preferences: "Your Preferences",
      key_also: "Also",
      key_connect_spotify: "CONNECT SPOTIFY",
      key_suffix_days: "Days",
      key_insights: "Insights",
      key_forgot_your_password: "Forgot Your Password?",
      key_email_sent: "Email Sent!",
      key_create_new_password: "Create New Password",
      key_forgot_password_no_email_exist:
        "User with same email does not exist, please check your email!",
      key_forgot_password_description:
        "Enter your registered email address and we will send you a verification code",
      key_verify_code_description:
        "Check your inbox and enter the verification code we sent you",
      key_code_not_valid: "Code is not valid.",
      key_reset_password_failure:
        "Your verify code is expired, please try again from sending forget password email.",
      key_title_edition: "EDITIONS",
      key_attach: "Attach",
      key_select_media_type: "Please select media type",
      key_ticket_empty: "No tickets available",
      key_festival_empty: "No festivals available",
      key_edition_empty: "No editions available",
      key_contact_empty: "No contact available",

      key_title_comment: "Comments",
      key_title_reply: "Replies",
      key_comment_placeholder: "Leave your thoughts here",
      key_no_events: "No Events",
      key_no_events_on_day: "No Performances for that day",
      key_fest_status_upcomming_confirmed: "UPCOMMING - CONFIRMED",
      key_fest_status_upcomming_to_be_updated: "TO BE UPDATED",
      key_fest_status_upcomming_live: "LIVE",
      key_fest_status_upcomming_canceled: "CANCELLED",
      key_fest_status_reschedule_to_be_updated: "RESCHEDULED - TO BE UPDATED",
      key_fest_status_past: "PAST",
      key_select_only_one_image: "Please select one media.",
      key_confirm_sync_spotify_follower:
        "Do you want to sync the Spotify artists you follow?",

      // Website
      key_web_mag: "Mag",
      key_web_pro: "Pro",
      key_web_store: "Store",
      key_web_contribute: "Contribute",
      key_web_apply: "APPLY",
      key_web_home_hero_title: `${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
      key_web_discover: "Discover",
      key_web_festivals: "Festivals",
      key_web_events: "Events",
      key_web_artists: "Artists",
      key_web_venues: "Venues",
      key_web_bars: "Bars",
      key_web_rooftops: "Rooftops",
      key_web_spas: "Spas",
      key_web_beachclubs: "Beach Clubs",
      key_web_prefab_company: "Companies",
      key_web_prefab_home: "Homes",
      key_web_copyright: `© ${moment().format("YYYY")} ${
        process.env.NEXT_PUBLIC_APP_NAME
      }. All rights reserved.`,
      key_web_help_center: "Help Center",
      key_web_community_guidelines: "Community Guidelines",
      key_web_media_kit: "Media Kit",
      key_web_cookies: "Cookies",
      key_web_legal: "Legal",
      key_web_sitemap: "Sitemap",
      key_web_home_app_1: "ONE APP. ALL VENUES, FESTIVALS & ARTISTS.",
      key_web_home_app_2:
        "DISCOVER FESTIVAL TICKETS, REVIEWS, LINEUPS AND MUCH MORE.",
      key_web_home_app_3: "+5k FESTIVALS  |  +1m ARTISTS",
      key_web_home_app_4: "JOIN US NOW",
      key_web_home_screenshot_title: "EVERY LIVE MUSIC EXPERIENCE IN YOUR HAND",
      key_web_home_feature_title: "FEATURES",
      key_web_home_about_title: "ABOUT",
      key_web_home_about_text_1:
        "We are the social platform for music festivals.",
      key_web_home_about_text_2:
        "We help you discover them and connect with the vibe you need.",
      key_web_home_career_title: "CAREERS",
      key_web_home_career_text_1:
        "We always welcome driven individuals passionate about live music.",
      key_web_home_career_text_2: "Check our open positions on our ",
      key_web_home_career_text_3: "AngelList profile.",
      key_web_home_career_text_4: "For voluntary applications, reach us at",
      key_web_home_contact_title: "CONTACT",
      key_web_home_contact_item_1_title: "General",
      key_web_home_contact_item_1_subtitle: "For general enquiries",
      key_web_home_contact_item_2_title: "Press",
      key_web_home_contact_item_2_subtitle:
        "For the press looking to get in touch",
      key_web_home_contact_item_3_title: "Business",
      key_web_home_contact_item_3_subtitle:
        "For parties interested to work with us",
      key_web_home_contact_item_4_title: "Advertising",
      key_web_home_contact_item_4_subtitle:
        "Discover how to reach our audience",
      key_web_home_contact_item_5_title: "Report",
      key_web_home_contact_item_5_subtitle:
        "For reporting copyright infringement or any mistake",
      key_web_home_contact_item_6_title: "Apply",
      key_web_home_contact_item_6_subtitle:
        process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",")?.find(
          (i) => i === "Festivals"
        )
          ? "Add or claim your festival on our platform"
          : "Add or claim your venue on our platform",
      key_web_here: `${process.env.NEXT_PUBLIC_APP_NAME} Pro`,
      key_web_office: "Office",
      key_web_home_newsletter_title: "NEWSLETTER",
      key_web_home_newsletter_subtitle:
        "Get all the latest updates delivered straight to your inbox",
      key_web_button_clear_all: "Clear All",
      key_update_festival: "Update / Edit Festival",
      key_report_mistake: "Report mistake",
      key_declaim_festival: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the events, it only reports information estimates for news and criticism purposes. The event organizer will show the exact information.`,
      key_declaim_artist: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the artist, it only reports information estimates for news and criticism purposes. The artist will show the exact information.`,
      key_declaim_venue: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the venues, it only reports information estimates for news and criticism purposes. The venue will show the exact information.`,
      key_declaim_prefab: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the manufacturer, it only reports information estimates for news and criticism purposes. The manufacturer will show the exact information.`,
      key_declaim_zoopedia: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the zoos & animal parks, it only reports information estimates for news and criticism purposes. The zoo/animal park will show the exact information.`,
      key_declaim_parkful: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the amusement parks, it only reports information estimates for news and criticism purposes. The park will show the exact information.`,
      key_declaim_casino: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the casinos, it only reports information estimates for news and criticism purposes. The casino will show the exact information.`,
      key_declaim_castle: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the castles, it only reports information estimates for news and criticism purposes. The castle will show the exact information.`,
      key_declaim_resort: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the resorts, it only reports information estimates for news and criticism purposes. The resort will show the exact information.`,
      key_declaim_aparthotel: `${process.env.NEXT_PUBLIC_APP_NAME} has no association with the aparthotels, it only reports information estimates for news and criticism purposes. The aparthotel will show the exact information.`,
    },
  },
};
