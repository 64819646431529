export const pageview = (url) => {
  if (process.env.NEXT_PUBLIC_GTAG_MANAGER !== "") {
    window.gtag("config", process.env.NEXT_PUBLIC_GTAG_MANAGER, {
      page_path: url,
    });
  }
};

export const event = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
